import "../css/NotFound.css"

export default function NotFound() {
    return (
        <div id="not_found">
            <h1>Ooops....</h1>
            <p>Could not find what you were looking... :(</p>
        </div>
    )
}
